import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col, Table, Card, Button } from 'react-bootstrap';
import { TOGCharacter } from '../../../modules/tog/common/components/tog-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const TOGGuidesRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/tog/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/tog/categories/category_reroll.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Tower of God: New World Reroll</h1>
          <h2>How to reroll in Tower of God: New World and who to aim for.</h2>
          <p>
            Last updated: <strong>25/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Reroll" />
        <p>
          Rerolling in Tower of God: New World is both{' '}
          <strong>worthwhile and easy to do</strong>. Worthwhile, because the 6
          SSR+ characters available on release are quite stronger than SSR ones
          and having one of them will make your early progression much easier -{' '}
          <strong>
            especially that dupes in the game don't increase rarity or the level
            cap
          </strong>{' '}
          (instead the affect the skill level), so you can use an SSR+ for a
          very long time and it won't lag behind your other characters.
        </p>
        <p>
          And it's also very easy to do! It only{' '}
          <strong>takes like 5-7 minutes to unlock the Summoning System</strong>{' '}
          and you start with roughly 30 pulls (10 come from tutorial and 20 from
          pre-reg/other rewards). On top of that, the game has{' '}
          <strong>in-build account deletion system</strong>, so you don't have
          to worry about salting emails and other methods commonly used in other
          games.
        </p>
        <p>
          To make the rerolling process easier, we suggest using{' '}
          <strong>BlueStacks</strong> as it supports multi-instance rerolling
          and allows you to play on your PC! BlueStacks also allows you to use
          Macros to optimize your experience - you can save a lot of time by
          creating macros that do the boring stuff for you!
        </p>
        <div className="banner-tog">
          <div className="cta">
            <h4>Play Tower of God: New World on PC & Browser!</h4>
            <OutboundLink href="https://bstk.me/mjePE2cUM" target="_blank">
              <Button variant="primary">Play now on BlueStacks</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Multi-instance" />
        <p>
          <strong>Important!</strong> If you decide to reroll using the
          multi-instance route, make sure to first create what we're calling a
          Master Instance. Here are the steps:
        </p>
        <ol>
          <li>
            Create a fresh emulator instance and install the game there - this
            will be your Master Instance and you will never launch the game on
            it,
          </li>
          <ul>
            <li>
              You can either login to your Google Account and install the game
              from the Store directly or install QooApp first and then the game,
            </li>
          </ul>
          <li>
            Clone the instance (how many times it depends how good your PC is),
          </li>
          <li>
            Open each cloned instance - you will have to download a ~2.5GB
            in-game patch on each though, so keep that in mind.
          </li>
          <li>Now you're ready to follow the rest of the process!</li>
        </ol>
        <SectionHeader title="The process" />
        <p>
          Just launch the game and play through the tutorial - you will learn
          about the combat and other basic systems.
        </p>
        <p>Tips and tricks:</p>
        <ul>
          <li>
            whenever you will see the skip button in the top right corner, just
            click it,
          </li>
          <li>
            keep in mind that names are unique per server - so just leave the
            default ID name the game generated for you and change it later,
          </li>
          <li>
            you can enable x2 speed and auto skills in the 1-1 stage, so do it
            to make the reroll faster. You can find the icons in bottom right
            corner of the screen,
          </li>
          <li>
            by default the Ultimate animations are on, so click on the icon to
            disable them (it's the first icon from left).
          </li>
        </ul>
        <p>After clearing the 1-4 stage, you will unlock Summoning:</p>
        <Row xs={1} xl={3}>
          <Col>
            <StaticImage
              src="../../../images/tog/guides/summon.jpg"
              alt="Guides"
            />
          </Col>
        </Row>
        <p>
          You will always get a SSR characters in the tutorial 10-pull, but
          there's a chance to get more than one AND you can also get an SSR+
          character here.
        </p>
        <p>To get the additional 20 tickets go here:</p>
        <ul>
          <li>
            Missions &gt; Achievements screen - for clearing stage 1-3 (10
            tickets),
          </li>
          <li>Mail (10 tickets).</li>
        </ul>
        <p>
          Before you do the rest of your pulls, make sure to setup your
          Wishlist! The characters you put on the Wishlist have a higher chance
          to appear when you get a SSR character. To learn more about this
          feature, check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Wishlist tips"
            link="/tog/guides/wishlist-tips"
            image={
              <StaticImage
                src="../../../images/tog/categories/category_wishlist.png"
                alt="Wishlist tips"
              />
            }
          />
        </Row>
        <h5>Codex and additional pulls</h5>
        <Row xs={1} xl={3}>
          <Col>
            <StaticImage
              src="../../../images/tog/guides/codex.jpg"
              alt="Guides"
            />
          </Col>
          <Col>
            <StaticImage
              src="../../../images/tog/guides/codex2.jpg"
              alt="Guides"
            />
          </Col>
        </Row>
        <p>
          Once you do the 20 pulls, you can also head to the{' '}
          <strong>Codex</strong> and check the Story tab to get 100 Gems per
          character. This combined with the getting the rewards from Ranking
          Board should give you enough to make another 10 pull (you need 3000
          Gems), but it takes quite a time to click through all the characters
          and we found out it's faster to just reroll the account.
        </p>
        <p>
          If luck wasn't on your side, click on the account portrait in top left
          corner of the Lobby and then switch to Misc tab - there you will find
          the Delete Account option:
        </p>
        <Row xs={1} xl={3}>
          <Col>
            <StaticImage
              src="../../../images/tog/guides/delete.jpg"
              alt="Guides"
            />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
        <SectionHeader title="Who to aim for" />
        <p>
          For the purpose of the reroll process, you will want to aim for the
          SSR+ characters - they are stronger than normal SSR and will make your
          progressions much smoother.
        </p>
        <p>Here's more information about the SSR+ worth rolling for:</p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Character</th>
              <th>Role</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="fast-ship-evan"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>Support</td>
              <td>
                Evan is hands down the best Support in the game. He can heal the
                team, give his allies energy and remove debuffs from them, but
                his biggest strength lies in the ability to make the whole team
                immortal - this allows you to cheese stages where normally you
                would be stuck.
                <br /> <br />
                And with how the dupe system works in this game, you will be
                able to use him for a long time as Supports aren't as reliant on
                skill multipliers as damage dealers or tanks.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="data-zahard"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>DPS / Tank</td>
              <td>
                Data Zahard is very similar to Data Mascheny as both fill the
                stall tank role, but Zahard has the advantage of also providing
                debuffs for the team and also boasting outrageous damage once
                you level up his unique weapon and get a few dupes.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="data-khun-mascheny"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>DPS / Tank</td>
              <td>
                While Data Mascheny is classified as Assassin, she actually
                works really well as a Tank - and that's possible thanks to her
                Immortality passive. When you fight under high deficit (enemy
                has twice the power of your team), most Tanks die instantly and
                in that scenario using someone like Masch will buy your team a
                few seconds to charge their ultimates.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="steel-armored-karaka"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>Tank</td>
              <td>
                Another tank who is the pillar of the Purple team. He can buy
                your team enough time to deal with any threat, but even by
                himself he can do a lot of damage (though it's RNG based).
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          Currently we suggest rerolling for <strong>Evan</strong> as honestly,
          his kit is packed AND he will last you longer - eventually the damage
          dealers will start falling behind the SSR characters with upgraded
          skills (via dupes), but Support characters give you a lot of utility
          and they don't care that much about the multipliers on their skills.
        </p>
        <p>
          As for the other 3 choices, all of them work well already at 1 copy
          and they will make your story progression much easier - later on, each
          of them will form the backbone of the 2/3 teams you will need to
          build.
        </p>
        <SectionHeader title="Notable SSR" />
        <p>
          To learn more about the SSR characters that stand out from the crowd,
          check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Early team progression"
            link="/tog/guides/early-team-progression"
            image={
              <StaticImage
                src="../../../images/tog/categories/category_team.png"
                alt="Early team progression"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default TOGGuidesRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll | Tower of God: New World | Prydwen Institute"
    description="How to reroll in Tower of God: New World and who to aim for."
    game="tog"
  />
);
